import { FC, PropsWithChildren } from "react";

import { PingVisionModal } from "components/modals";

import "./PVSidebarModal.scss";

type PVSidebarModalProps = PropsWithChildren<{
  isOpen: boolean;
  onClose(): void;
  renderContent(): React.ReactNode;
}>;

const PVSidebarModal: FC<PVSidebarModalProps> = ({
  isOpen,
  onClose,
  renderContent,
}) => {
  return (
    <PingVisionModal
      className={{
        base: "PVSidebarModal",
        afterOpen: "PVSidebarModal--afterOpen",
        beforeClose: "PVSidebarModal--beforeClose",
      }}
      overlayClassName={{
        base: "PVSidebarModal__Overlay",
        afterOpen: "PVSidebarModal__Overlay--afterOpen",
        beforeClose: "PVSidebarModal__Overlay--beforeClose",
      }}
      contentClassName="PVSidebarModal__Content"
      isOpen={isOpen}
      onClose={onClose}
      renderContent={renderContent}
      contentOnly
    />
  );
};

export { PVSidebarModal };
