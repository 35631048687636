import { FC, useState, useMemo } from "react";
import { useAppSelector } from "utils/redux";

import cx from "classnames";
import {
  formatDateTimeStringWithAgo,
  formatShortDateTime,
  PingMaterialIcon,
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "@repo/ping-react-js";
import { findSubmissionTitle } from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionListItem.scss";
import { PVSubmissionPill } from "./PVSubmissionPill";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";

type PingVisionSubmissionListItemProps = {
  sov: SovDataType;
  isSelected: boolean;
  onClickIncomingItem: (id: string) => void;
  onCheckboxChange: (id: string, checked: boolean) => void;
  itemIsChecked?: boolean;
};

export const PingVisionSubmissionListItem: FC<
  PingVisionSubmissionListItemProps
> = ({
  sov,
  isSelected,
  onClickIncomingItem,
  onCheckboxChange,
  itemIsChecked = false,
}) => {
  const { setMode } = usePingVisionUrlStore();
  const userIdToUserMap = useAppSelector(
    (state) => state.settings.userIdToUserMap,
  );
  const isClaimed = sov.claimed_by_id !== null;
  const [isChecked, setIsChecked] = useState(itemIsChecked);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onCheckboxChange(sov.id, e.target.checked);
    setIsChecked(!isChecked);
  };

  const claimedContent = useMemo(() => {
    return !sov.claimed_by_id ? null : (
      <PingTooltip placement="right-end">
        <PingTooltipTrigger asChild>
          <div className="PingVisionSubmissionListItem__Status__Claim">
            <PingMaterialIcon
              className="PingVisionSubmissionListItem__Status__Icon"
              iconName={"supervised_user_circle"}
            />
            {`${userIdToUserMap?.[sov.claimed_by_id]?.full_name}` ||
              "Missing Full Name"}
          </div>
        </PingTooltipTrigger>
        <PingTooltipContent>
          {userIdToUserMap?.[sov.claimed_by_id]?.username}
        </PingTooltipContent>
      </PingTooltip>
    );
  }, [sov.claimed_by_id, userIdToUserMap]);

  const unclaimedContent = useMemo(() => {
    return (
      <div className="PingVisionSubmissionListItem__Status__Claim">
        <PingMaterialIcon
          className="PingVisionSubmissionListItem__Status__Icon"
          iconName="no_accounts"
        />
        <div>Unassigned</div>
      </div>
    );
  }, []);

  return (
    <li
      className={cx("PingVisionSubmissionListItem", {
        "PingVisionSubmissionListItem--Selected": isSelected,
        "PingVisionSubmissionListItem--Checked": isChecked,
      })}
      onClick={() => onClickIncomingItem(sov.id)}
      onDoubleClick={() => {
        setMode(APP_MODES.DETAIL);
      }}
    >
      <div className="PingVisionSubmissionListItem__Content">
        <div className="PingVisionSubmissionListItem__Heading">
          <div className="PingVisionSubmissionListItem__Heading__TitleWrapper">
            <div className="PingVisionSubmissionListItem__Checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <div className="PingVisionSubmissionListItem__Heading__Title">
              {findSubmissionTitle(sov)}
            </div>
          </div>

          <div className="PingVisionSubmissionListItem__Heading__Date">
            <PingTooltip placement="left-end">
              <PingTooltipTrigger asChild>
                {formatDateTimeStringWithAgo(sov.created_time)}
              </PingTooltipTrigger>
              <PingTooltipContent>
                Created: {formatShortDateTime(sov.created_time)}, last modified:{" "}
                {formatShortDateTime(sov.modified_time)}
              </PingTooltipContent>
            </PingTooltip>
            {" • "}
            {sov.workflow_status__name}
          </div>
        </div>

        <div className="PingVisionSubmissionListItem__Status">
          {isClaimed ? claimedContent : unclaimedContent}
          <PVSubmissionPill sov={sov} isSelected={isSelected} />
        </div>
      </div>
    </li>
  );
};
