import { useState } from "react";
import cx from "classnames";
import { PingVisionModal } from "./PingVisionModal";
import { PingButton, PingTextInput } from "@repo/ping-react-js";

import "./FileRenameModal.scss";

const FileRenameModal = ({ isOpen, onClose, onClickOk }) => {
  const [newFileName, setNewFileName] = useState("");

  return (
    <PingVisionModal
      className={{ base: "FileRenameModal" }}
      isOpen={isOpen}
      onClose={onClose}
      title="Rename Attachment"
      renderFooter={() => (
        <>
          <PingButton
            label="Cancel"
            onClick={() => {
              onClose();
            }}
          />
          <PingButton
            disabled={!newFileName?.length}
            className={cx("FileRenameModal__OkButton", {
              "FileRenameModal__OkButton--enabled": newFileName?.length > 5,
            })}
            label="Rename"
            onClick={() => {
              onClickOk(newFileName);
            }}
          />
        </>
      )}
      renderContent={() => (
        <div className="FileRenameModal__Content">
          <div className="FileRenameModal__Content__Text">
            <PingTextInput
              type="text"
              inputClassName=""
              value={newFileName}
              onChange={(e) => {
                setNewFileName(e.target.value);
              }}
              placeholder={"Enter new name"}
              name="new_file_name"
            />
          </div>
        </div>
      )}
    />
  );
};

export default FileRenameModal;
