import { FC, useCallback, useState } from "react";
import {
  Document as ReactPdfDocument,
  Page,
  pdfjs,
  DocumentProps,
} from "react-pdf";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import { PingCircleIconButton } from "../inputs/PingCircleIconButton";
import { PingButton } from "../inputs/PingButton";

import "./PingPdfPreview.scss";

// // Configure the worker URL directly - you can adjust this path based on your project structure
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const CustomLoading = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh", // Full height of the viewport or parent container
      textAlign: "center",
    }}
  >
    <div>
      <h3>Loading your PDF...</h3>
      <p>Please wait a moment</p>
    </div>
  </div>
);

type PDFPreviewProps = {
  file: string;
  documentOptions?: DocumentProps["options"];
  onClose?(): void;
};

const INITIAL_PDF_WIDTH = 750;

export const PingPdfPreview: FC<PDFPreviewProps> = ({
  file,
  documentOptions,
  onClose,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    },
    [],
  );

  return (
    <div className="PingPdfPreview">
      <div className="PingPdfPreview__Controls">
        <div className="PingPdfPreview__Controls__NavigationButtons">
          {onClose && (
            <PingCircleIconButton iconName="close" onClick={onClose} />
          )}
          <PingButton
            label="← Prev"
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber <= 1}
          />
          <PingButton
            label="Next →"
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={numPages !== null && pageNumber >= numPages}
          />
        </div>
        <div className="PingPdfPreview__Controls__PageInfo">
          {numPages && `Page ${pageNumber} of ${numPages}`}
        </div>
      </div>
      <div className="PingPdfPreview__PdfContainer">
        <ReactPdfDocument
          loading={CustomLoading}
          file={file as string}
          onLoadSuccess={onDocumentLoadSuccess}
          className="PingPdfPreview__PdfContainer__PdfDocument"
          options={documentOptions}
        >
          <Page pageNumber={pageNumber} width={INITIAL_PDF_WIDTH} />
        </ReactPdfDocument>
      </div>
    </div>
  );
};
