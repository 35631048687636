import { useContext, createContext } from "react";

type PanelContextType = {
  isLeftNavVisible?: boolean;
  isSubmissionListVisible?: boolean;
};

export const PanelContext = createContext<PanelContextType>({});

export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error(
      "usePanelContext must be used within a PanelContextProvider",
    );
  }
  return context;
};
