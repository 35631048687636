import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useWindowSize } from "@uidotdev/usehooks";

import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PingVisionSubmissionDetails } from "features/submission-dashboard/PingVisionSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { PVAllDocumentsPreviewPanel } from "features/submission-dashboard/PVAllDocumentsPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import {
  useGetSubmissionList,
  useGetSubmissionHistoryList,
} from "features/submission-dashboard/queries";
import {
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { useAppSelector } from "utils/redux";
import { useSlug, usePingId } from "utils/hooks";
import {
  setSovs,
  setSubmissionHistory,
  setPreviewPdf,
  setClearPreview,
  setPreviewEmail,
} from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { SovDataType } from "ts-types/DataTypes";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { PanelContext } from "utils/context";

import "./PingVisionSubmissionDashboard.scss";

const SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX = 1440;
const SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX = 1100;

// add SOV here later
type DocumentType = "ACORD" | "LOSS_RUN" | "EML";
// add SOV here later
const DOCUMENT_TYPES: DocumentType[] = ["EML", "ACORD", "LOSS_RUN"];

const enrichSovData = (
  sovData: { results: SovDataType[] } | undefined,
  submissionStatuses: { id: string; name: string }[] | undefined
) => {
  if (!sovData || !submissionStatuses) {
    return [];
  }

  return sovData.results.map((r) => ({
    ...r,
    workflow_status__name:
      submissionStatuses.find(
        (s) => s.id.toString() === r.workflow_status_id.toString()
      )?.name || "Unknown",
  }));
};

const PingVisionSubmissionDashboard = () => {
  const dispatch = useDispatch();
  const pingId = usePingId();

  const [isLeftNavVisible, setIsLeftNavVisible] = useState(true);
  const [isSubmissionListVisible, setIsSubmissionListVisible] = useState(true);

  const sovs = useAppSelector((state) => state.inbox.sovs);
  const submissionStatuses = useAppSelector(
    (state) => state.settings?.settings?.submission_status
  );

  const { mode } = usePingVisionUrlStore();

  const documentPreview = useAppSelector(
    (state) => state.inbox.documentPreview
  );

  const detailMode = mode === APP_MODES.DETAIL;

  const slug = useSlug();

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened
  );

  const selectedItem = useMemo(
    () => sovs.find((sov) => sov.id === pingId),
    [sovs, pingId]
  );

  const documents = useMemo(
    () => selectedItem?.documents || [],
    [selectedItem]
  );

  const documentsByType = useMemo(() => {
    return DOCUMENT_TYPES.reduce(
      (acc, type) => {
        const filteredDocuments = documents.filter(
          (d) => d.document_type === type
        );
        if (filteredDocuments.length > 0) {
          acc[type] = filteredDocuments;
        }
        return acc;
      },
      {} as Record<DocumentType, typeof documents>
    );
  }, [documents]);

  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  const handleOpenPreviewPanel = useCallback(() => {
    dispatch(setClearPreview());

    if (selectedItem && Object.keys(documentsByType).length > 0) {
      const documentTypes = Object.keys(documentsByType);

      if (documentTypes.includes("EML")) {
        dispatch(setPreviewEmail(selectedItem.id));
      } else if (documentTypes.includes("ACORD")) {
        const currentActiveDocuments = documentsByType["ACORD"];
        const firstDoc = currentActiveDocuments[0];
        dispatch(setPreviewPdf(firstDoc.url));
      } else if (documentTypes.includes("LOSS_RUN")) {
        const currentActiveDocuments = documentsByType["LOSS_RUN"];
        const firstDoc = currentActiveDocuments[0];
        dispatch(setPreviewPdf(firstDoc.url));
      }
      // Handle SOV display logic later
    }
  }, [selectedItem, documentsByType, dispatch]);

  useEffect(() => {
    setLocalSelectedItem(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    handleOpenPreviewPanel();
  }, [handleOpenPreviewPanel]);

  const { data: sovData } = useGetSubmissionList();

  useGetSettingsQuery({});

  useGetNavQuery({});

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryList();

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  useEffect(() => {
    const enrichedSovs = enrichSovData(sovData, submissionStatuses);
    dispatch(setSovs(enrichedSovs));
  }, [dispatch, submissionStatuses, sovData]);

  const windowSize = useWindowSize();
  useEffect(() => {
    if (
      windowSize.width &&
      windowSize.width > SUPPRESS_LEFT_NAV_SCREEN_WIDTH_PX
    ) {
      setIsLeftNavVisible(true);
    } else {
      setIsLeftNavVisible(false);
    }

    if (
      windowSize.width &&
      windowSize.width <= SUPPRESS_SUBMISSION_LIST_SCREEN_WIDTH_PX
    ) {
      setIsSubmissionListVisible(false);
    } else {
      setIsSubmissionListVisible(true);
    }
  }, [dispatch, windowSize, documentPreview]);

  return (
    <React.Fragment>
      <PanelContext.Provider
        value={{
          isLeftNavVisible,
          isSubmissionListVisible,
        }}
      >
        <PingCommandMenuModal
          isOpen={isCommandMenuOpened}
          onClose={() => {
            dispatch(setIsCommandMenuOpened(false));
          }}
        />
        <BaseLayout
          title="Ping Radar [beta]"
          shouldShowPoweredByPing={false}
          className="PingVisionSubmissionDashboard"
        >
          <MainContent
            hasTopPadding={false}
            paddingSize="slim"
            className="PingVisionSubmissionDashboard__Main"
          >
            <PanelGroup
              direction="horizontal"
              autoSaveId="submission-dashboard"
            >
              <Panel
                key={JSON.stringify({ isLeftNavVisible })}
                className="PingVisionSubmissionDashboard__Main__Panel"
                id="submission-dashboard-sidebar"
                order={1}
                minSize={isLeftNavVisible ? 8 : 2.5}
                maxSize={isLeftNavVisible ? 12 : 2.5}
              >
                <PVPanel background="none">
                  <PingVisionSidebar />
                </PVPanel>
              </Panel>
              {isLeftNavVisible && (
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              )}
              {slug && !detailMode && isSubmissionListVisible && (
                <>
                  <Panel
                    className="PingVisionSubmissionDashboard__Main__Panel"
                    id="submission-dashboard-submission-list"
                    order={2}
                    minSize={10}
                    maxSize={30}
                  >
                    <PVPanel>
                      <PingVisionSubmissionList />
                    </PVPanel>
                  </Panel>
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                </>
              )}
              <Panel
                className="PingVisionSubmissionDashboard__Main__Panel"
                id="submission-dashboard-submission-details"
                order={3}
                minSize={25}
                maxSize={88}
              >
                {localSelectedItem ? (
                  <PVPanel>
                    <PingVisionSubmissionDetails
                      selectedItem={localSelectedItem}
                      onOpenPreviewPanel={handleOpenPreviewPanel}
                      hasPreviewableDocuments={
                        Object.keys(documentsByType).length > 0
                      }
                    />
                  </PVPanel>
                ) : (
                  <PVPanel>
                    <PVEmptyPanelMessage message="Select a submission to view details" />
                  </PVPanel>
                )}
              </Panel>
              {documentPreview && selectedItem && (
                <>
                  <PingPanelResizeHandle
                    orientation="horizontal"
                    width="slim"
                  />
                  <Panel
                    id="submission-dashboard-file-preview-details"
                    minSize={30}
                    maxSize={88}
                    order={4}
                  >
                    <PVPanel>
                      <PVAllDocumentsPreviewPanel selectedItem={selectedItem} />
                    </PVPanel>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </MainContent>
        </BaseLayout>
      </PanelContext.Provider>
    </React.Fragment>
  );
};

export default PingVisionSubmissionDashboard;
