import { daysBetween } from "@repo/ping-react-js";

type InceptionDateStatus = {
  label: string;
  tooltip: string;
  additionalClassName: string | null;
};

const INCEPTION_DATE_STATUS_UNKNOWN: InceptionDateStatus = {
  label: "-",
  tooltip: "Inception date unknown",
  additionalClassName: null,
};

const getInceptionDateStatus = (
  inceptionDate: string | null,
  classNamePrefix: string
): InceptionDateStatus => {
  if (!inceptionDate) {
    return INCEPTION_DATE_STATUS_UNKNOWN;
  }

  const dti = daysBetween(inceptionDate, new Date());
  if (!dti) {
    return INCEPTION_DATE_STATUS_UNKNOWN;
  }

  const defaultLabel = dti.toString();
  const defaultTooltip = `Days to Inception: ${dti}`;

  if (dti < -30) {
    return {
      label: "past",
      tooltip: `Inception date has passed (${Math.abs(dti)} days ago)`,
      additionalClassName: `${classNamePrefix}--Past`,
    };
  } else if (dti < 0) {
    return {
      label: defaultLabel,
      tooltip: `Inception date has passed (${Math.abs(dti)} days ago)`,
      additionalClassName: `${classNamePrefix}--Past`,
    };
  } else if (dti < 30) {
    return {
      label: defaultLabel,
      tooltip: defaultTooltip,
      additionalClassName: `${classNamePrefix}--Warning`,
    };
  }

  return {
    label: defaultLabel,
    tooltip: defaultTooltip,
    additionalClassName: null,
  };
};

export { getInceptionDateStatus };
