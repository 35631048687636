import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  useQuery,
  PingQueryParameterType,
  replaceQueryParameters,
} from "@repo/ping-react-js";

const MODE_QUERY_PARAM = "mode";

export const usePingVisionUrlStore = () => {
  const searchParams = useQuery();
  const history = useHistory();

  const mode = searchParams.get(MODE_QUERY_PARAM);

  const setMode = useCallback(
    (value: PingQueryParameterType) => {
      replaceQueryParameters(searchParams, history, {
        [MODE_QUERY_PARAM]: value,
      });
    },
    [history, searchParams],
  );

  return { mode, setMode };
};
