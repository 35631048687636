import { FC, PropsWithChildren, useMemo } from "react";
import cx from "classnames";

import { daysBetween, formatShortFriendlyDate } from "@repo/ping-react-js";

import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { SovDataType } from "ts-types/DataTypes";
import { formatCurrency } from "utils/formatters";
import {
  DataReadinessLevel,
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
  TriageResultLevel
} from "utils/submission";
import { getInceptionDateStatus } from "features/submission-dashboard/inception-date-status";

import "./PVSubmissionSummary.scss";

const formatAddress = (item: SovDataType, prefix: "insured" | "broker") => {
  const street = item[`${prefix}_street`];
  const street2 = item[`${prefix}_street2`];
  const city = item[`${prefix}_city`];
  const state = item[`${prefix}_state`];
  const zip = item[`${prefix}_zip`];

  if (!street || !city || !state || !zip) {
    return <div>Incomplete or missing address.</div>;
  }

  return (
    <address>
      <div>
        {street}
        {street2 ? `, ${street2}` : null},
      </div>
      <div>
        {city}, {state} {zip}
      </div>
    </address>
  );
};

const toRelativeDateStringFromNow = (date: string | null) => {
  const daysUntil = daysBetween(date, new Date());

  let description;
  if (!date || !daysUntil) {
    description = "Unknown";
  } else {
    if (daysUntil < 0) {
      description = `${Math.abs(daysUntil)}d ago`;
    } else {
      description = `in ${daysUntil}d`;
    }
  }

  return description;
};

const triageResultLevelToClassName = (
  prefix: string,
  triageResultLevel?: TriageResultLevel
) => {
  let postfix = "Unknown";
  if (triageResultLevel === TriageResultLevel.Unknown) {
    postfix = "Unknown";
  } else if (triageResultLevel === TriageResultLevel.Approved) {
    postfix = "Approved";
  } else if (triageResultLevel === TriageResultLevel.Consider) {
    postfix = "Consider";
  } else if (triageResultLevel === TriageResultLevel.Declined) {
    postfix = "Declined";
  }

  return `${prefix}--${postfix}`;
};

const dataReadinessResultLevelToClassName = (
  prefix: string,
  dataReadinessLevel?: DataReadinessLevel
) => {
  let postfix = "Unknown";
  if (dataReadinessLevel === DataReadinessLevel.Unknown) {
    postfix = "Unknown";
  } else if (dataReadinessLevel === DataReadinessLevel.Low) {
    postfix = "Low";
  } else if (dataReadinessLevel === DataReadinessLevel.Medium) {
    postfix = "Medium";
  } else if (dataReadinessLevel === DataReadinessLevel.High) {
    postfix = "High";
  }

  return `${prefix}--${postfix}`;
};

type PVSubmissionSummaryProps = {
  selectedItem: SovDataType;
};

const PVSubmissionSummary: FC<PVSubmissionSummaryProps> = ({
  selectedItem
}) => {
  const triageResult = getHumanFriendlyTriageScore(
    selectedItem.triage_rules_overall_result
  );

  const dataReadinessResult = getHumanFriendlyDataReadinessScore(
    selectedItem.data_readiness_score
  );

  const inceptionDateStatus = useMemo(
    () =>
      getInceptionDateStatus(
        selectedItem?.inception_date,
        "PVSubmissionSummary__Content__Dates__DtiRow__Dti"
      ),
    [selectedItem?.inception_date]
  );

  return (
    <div className="PVSubmissionSummary">
      <PVScrollSpySectionHeader title="Summary" />
      <div className="PVSubmissionSummary__Content">
        <PVSubmissionSummaryCard title="Dates">
          <table className="PVSubmissionSummary__Content__Table PVSubmissionSummary__Content__Dates">
            <thead />

            <tbody>
              <tr>
                <th>Inception</th>
                <td>{formatShortFriendlyDate(selectedItem.inception_date)}</td>
              </tr>
              <tr className="PVSubmissionSummary__Content__Dates__DtiRow">
                <th>DTI</th>
                <td
                  className={cx(
                    "PVSubmissionSummary__Content__Dates__DtiRow__Dti",
                    inceptionDateStatus.additionalClassName
                  )}
                >
                  {toRelativeDateStringFromNow(selectedItem.inception_date)}
                </td>
              </tr>
              <tr>
                <th>Received</th>
                <td>{formatShortFriendlyDate(selectedItem.created_time)}</td>
              </tr>
              <tr>
                <th>Received since</th>
                <td>
                  {toRelativeDateStringFromNow(selectedItem.created_time)}
                </td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionSummaryCard>
        <PVSubmissionSummaryCard title="Status Checks">
          <table className="PVSubmissionSummary__Content__Table">
            <thead />

            <tbody>
              <tr>
                <th>UW Rules</th>
                <td
                  className={triageResultLevelToClassName(
                    "PVSubmissionSummary__Content__Table__TriageResult",
                    triageResult?.level
                  )}
                >
                  {triageResult?.label}
                </td>
              </tr>
              <tr>
                <th>Data readiness</th>
                <td
                  className={dataReadinessResultLevelToClassName(
                    "PVSubmissionSummary__Content__Table__DataReadiness",
                    dataReadinessResult?.level
                  )}
                >
                  {dataReadinessResult.label}
                </td>
              </tr>
              <tr>
                <th>OFAC</th>
                <td className="PVSubmissionSummary__Content__Table__OFAC--Pass">
                  Pass
                </td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionSummaryCard>
        <PVSubmissionSummaryCard title="Documents">
          <div className="PVSubmissionSummary__Content__Documents">
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                Email
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                Re: This is just a demo email subject
              </div>
            </div>
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                SOV
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                Demo_File_Nov2024__SOV.xlsx
              </div>
            </div>
            <div className="PVSubmissionSummary__Content__Documents__Row">
              <div className="PVSubmissionSummary__Content__Documents__Row__PillLabel">
                ACORD
              </div>
              <div className="PVSubmissionSummary__Content__Documents__Row__Filename">
                ACORD Ping Data November.pdf
              </div>
            </div>
          </div>
        </PVSubmissionSummaryCard>
        <PVSubmissionSummaryCard title="Insured">
          <div className="PVSubmissionSummary__Content__Insured">
            <div>{formatAddress(selectedItem, "insured")}</div>
            <div>
              <strong>Home state:</strong>{" "}
              {selectedItem.home_state ? (
                <span>{selectedItem.home_state}</span>
              ) : (
                <a href="#insured-information">Unknown</a>
              )}
            </div>
          </div>
        </PVSubmissionSummaryCard>
        <PVSubmissionSummaryCard title="Broker">
          <div className="PVSubmissionSummary__Content__Broker">
            <div>{selectedItem?.broker_name}</div>
            <div>{formatAddress(selectedItem, "broker")}</div>
            <div>
              <strong>Sender:</strong>{" "}
              {selectedItem.source__source_type === "E" &&
                selectedItem.from_email}
              {selectedItem.source__source_type === "A" && "API"}
            </div>
          </div>
        </PVSubmissionSummaryCard>
        <PVSubmissionSummaryCard title="Submission summary">
          <table className="PVSubmissionSummary__Content__Table">
            <thead />
            <tbody>
              <tr>
                <th>Buildings</th>
                <td>{selectedItem.num_buildings?.toString() ?? "Unknown"}</td>
              </tr>
              <tr>
                <th>TIV</th>
                <td>
                  {formatCurrency(selectedItem.limits_total_limit, true) ||
                    "Unknown"}
                </td>
              </tr>
            </tbody>
          </table>
        </PVSubmissionSummaryCard>
      </div>
    </div>
  );
};

type PVSubmissionSummaryCardProps = {
  title: string;
  uiSize?: "normal" | "wide";
} & PropsWithChildren;

const PVSubmissionSummaryCard: FC<PVSubmissionSummaryCardProps> = ({
  title,
  uiSize = "normal",
  children
}) => {
  const appliedClasses = cx("PVSubmissionSummaryCard", {
    "PVSubmissionSummaryCard--IsWide": uiSize === "wide"
  });

  return (
    <div className={appliedClasses}>
      <div className="PVSubmissionSummaryCard__Title">{title}</div>
      <div className="PVSubmissionSummaryCard__Content">{children}</div>
    </div>
  );
};

export { PVSubmissionSummary };
