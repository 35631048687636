import { FC, useCallback, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { addYears } from "date-fns";

import { PingButton } from "@repo/ping-react-js";

import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "features/submission-dashboard/PVFormsLayouts";
import {
  PVErrorDisplay,
  PVTextInputFormField,
} from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";
import { emptyStringToNull } from "utils/zod-utils";
import { SovDataType } from "ts-types/DataTypes";

import "./PVOverviewGrid.scss";

type PVOverviewGridProps = {
  selectedItem: SovDataType;
};

const formSchema = z.object({
  inception_date: emptyStringToNull(),
  expiration_date: emptyStringToNull(),
  client_ref: emptyStringToNull(),
});

type FormInput = z.infer<typeof formSchema>;

export const PVOverviewGrid: FC<PVOverviewGridProps> = ({ selectedItem }) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormInput>({ resolver: zodResolver(formSchema) });

  const inception_date = watch("inception_date");

  const [updateSubmission, { isLoading: isSubmitting }] =
    useUpdateSubmissionTriageMutation();

  useEffect(() => {
    if (inception_date) {
      const newExpirationDate = addYears(new Date(inception_date), 1)
        .toISOString()
        .split("T")[0];
      setValue("expiration_date", newExpirationDate);
    }
  }, [inception_date, setValue]);

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const result = await updateSubmission({ id: selectedItem.id, data });
      if (!result.error) {
        setIsEditing(false);
        return;
      }

      setServerErrors(result.error.data, setError);
    },
    [updateSubmission, selectedItem.id, setError],
  );

  const onReset = useCallback(() => {
    reset();
    setIsEditing(false);
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="PVOverviewGrid">
      <PVScrollSpySectionHeader title="Basic Overview">
        {isEditing ? (
          <>
            <PingButton
              label="Cancel"
              type="button"
              onClick={onReset}
              disabled={isSubmitting}
            />
            <PingButton
              label={isSubmitting ? "Saving …" : "Save"}
              type="submit"
              variant="primary"
              disabled={isSubmitting}
            />
          </>
        ) : (
          <PingButton
            label="Edit"
            type="button"
            onClick={() => setIsEditing(true)}
          />
        )}
      </PVScrollSpySectionHeader>
      <PVInputsColumnsGrid>
        <PVInputsColumn>
          {errors.root?.serverError && (
            <PVErrorDisplay>{errors.root?.serverError.message}</PVErrorDisplay>
          )}

          <PVTextInputFormField
            label="Inception date"
            name="inception_date"
            id="inception_date"
            type="date"
            currentValue={selectedItem.inception_date}
            register={register}
            error={errors.inception_date}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Expiration date"
            name="expiration_date"
            id="expiration_date"
            type="date"
            currentValue={selectedItem.expiration_date}
            register={register}
            error={errors.expiration_date}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Client reference"
            name="client_ref"
            id="client_ref"
            currentValue={selectedItem.client_ref}
            register={register}
            error={errors.client_ref}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
