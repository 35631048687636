import { FC, useCallback, useRef } from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";

import { PingCircleIconButton } from "../inputs/PingCircleIconButton";

import "./PingEmailPreview.scss";

interface EmailThread {
  subject: string;
  html: string;
  from_address: string;
  to_addresses: string;
  cc_addresses?: string;
  source_email_time: string;
}

function formatDateTime(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
}

function splitAddresses(addresses: string | undefined): string[] {
  if (!addresses) return [];
  return addresses
    .split(/[;,]+/)
    .map((addr) => addr.trim())
    .filter((addr) => addr);
}

type PingEmailPreviewProps = {
  content: EmailThread[];
  onClose(): void;
};

export const PingEmailPreview: FC<PingEmailPreviewProps> = ({
  content,
  onClose,
}) => {
  let messageCount = "No messages";
  if (content.length > 0) {
    messageCount = `${content.length} ${content.length > 1 ? "messages" : "message"}`;
  }

  const frameRef = useRef(null);
  const frameDocumentRef = useRef<null | Document>(null);

  const onLoadFrame = useCallback(() => {
    const height = Math.max(
      frameDocumentRef.current.documentElement.clientHeight,
      frameDocumentRef.current.documentElement.scrollHeight,
      frameDocumentRef.current.documentElement.offsetHeight,
      frameDocumentRef.current.body.scrollHeight,
      frameDocumentRef.current.body.offsetHeight,
    );

    frameRef.current.style.height = `${height}px`;
  }, []);

  console.log("email preview content", content);

  return (
    <div className="PingEmailPreview">
      <div className="PingEmailPreview__Controls">
        <div className="PingEmailPreview__Controls_NavigationButtons">
          {onClose && (
            <PingCircleIconButton iconName="close" onClick={onClose} />
          )}
        </div>
        <div className="PingEmailPreview__Controls_PageInfo">
          {messageCount}
        </div>
      </div>
      {content.map((email, index) => (
        <div key={index} className="PingEmailPreview__EmailThread">
          <div className="PingEmailPreview__EmailThread__Header">
            <div>
              <strong>From:</strong> {email.from_address}
            </div>
            <div>
              <strong>To:</strong>{" "}
              {splitAddresses(email.to_addresses).join(", ")}
            </div>
            {email.cc_addresses &&
              splitAddresses(email.cc_addresses).length > 0 && (
                <div>
                  <strong>CC:</strong>{" "}
                  {splitAddresses(email.cc_addresses).join(", ")}
                </div>
              )}
            <div>
              <strong>Date:</strong> {formatDateTime(email.source_email_time)}
            </div>
            <div>
              <strong>Subject:</strong> {email.subject}
            </div>
          </div>
          <Frame className="PingEmailPreview__EmailThread__Body" ref={frameRef}>
            <FrameContextConsumer>
              {({ document }) => {
                if (!frameDocumentRef.current) {
                  frameDocumentRef.current = document;
                  setTimeout(() => onLoadFrame(), 0);
                }
                return <div dangerouslySetInnerHTML={{ __html: email.html }} />;
              }}
            </FrameContextConsumer>
          </Frame>
        </div>
      ))}
    </div>
  );
};
