import { useMemo } from "react";
import ReactModal from "react-modal";
import cx from "classnames";

import { DialogHeaderBar } from "@repo/ping-react-js";

import "./PingVisionModal.scss";

type PingVisionModalPropTypes = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  renderContent: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  className?: {
    base?: string;
    afterOpen?: string;
    beforeClose?: string;
  };
  overlayClassName?: {
    base?: string;
    afterOpen?: string;
    beforeClose?: string;
  };
  contentClassName?: string;
  contentOnly?: boolean;
};

ReactModal.setAppElement("#app");

export const PingVisionModal = ({
  isOpen,
  onClose,
  title,
  renderContent,
  renderFooter,
  className,
  overlayClassName,
  contentClassName,
  contentOnly = false,
}: PingVisionModalPropTypes) => {
  const appliedClasses = {
    base: cx("PingVisionModal", className?.base),
    afterOpen: cx("PingVisionModal--afterOpen", className?.afterOpen),
    beforeClose: cx("PingVisionModal--beforeClose", className?.beforeClose),
  };
  const appliedOverlayClasses = {
    base: cx("PingVisionModal__Overlay", overlayClassName?.base),
    afterOpen: cx(
      "PingVisionModal__Overlay--afterOpen",
      overlayClassName?.afterOpen
    ),
    beforeClose: cx(
      "PingVisionModal__Overlay--beforeClose",
      overlayClassName?.beforeClose
    ),
  };
  const appliedContentClasses = cx(
    "PingVisionModal__Content",
    contentClassName
  );

  const modalContent = useMemo(() => {
    return contentOnly ? (
      <div className={appliedContentClasses}>{renderContent()}</div>
    ) : (
      <>
        <DialogHeaderBar className="PingVisionModal__Header">
          {title && (
            <div className="PingVisionModal__Header__Title">{title}</div>
          )}
        </DialogHeaderBar>
        <div className={appliedContentClasses}>{renderContent()}</div>
        {renderFooter && (
          <DialogHeaderBar className="PingVisionModal__Footer" border="top">
            {renderFooter()}
          </DialogHeaderBar>
        )}
      </>
    );
  }, [contentOnly, renderContent, renderFooter, title, appliedContentClasses]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose} // Add this line
      className={appliedClasses}
      overlayClassName={appliedOverlayClasses}
      closeTimeoutMS={300}
    >
      {modalContent}
    </ReactModal>
  );
};
