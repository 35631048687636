import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "src/utils/redux";
import omit from "lodash/omit";
import { useSlug, useTeamId, usePingId } from "utils/hooks";
import isFunction from "lodash/isFunction";

import {
  PingSearchQueryBuilderField,
  TEXT_SEGMENT_KEY,
  useSearchQueryBuilder
} from "@repo/ping-react-js";

import { PING_VISION_DEFAULT_FIELDS } from "constants/ApiConstants";
import {
  useGetSubmissionsQuery,
  useGetSubmissionHistoryQuery
} from "services/pvSlice";

export const SEARCH_PARAM_NAME = "search";

const getName = (t: Record<string, string>) => t.username || "";

export const useGetAdvancedSearchFields = (): {
  advancedSearchFields: PingSearchQueryBuilderField[];
} => {
  const settings = useAppSelector((state) => state.settings.settings);
  const advancedSearchFields: PingSearchQueryBuilderField[] = useMemo(() => {
    return [
      {
        label: "Readiness score",
        fieldName: "readiness",
        type: "range"
      },
      {
        label: "User",
        fieldName: "claimed_by_id",
        type: "dropdown",
        options: settings?.users?.map((t) => ({
          label: getName(t),
          value: t.id.toString()
        }))
      },
      {
        label: "Workflow Status",
        fieldName: "workflow_status_id",
        type: "dropdown",
        options: settings?.submission_status?.map((s) => ({
          label: s.name,
          value: s.id.toString()
        }))
      },
      {
        label: "Team Name",
        fieldName: "team_id",
        type: "dropdown",
        options: settings?.teams?.map((t) => ({
          label: t.team_name,
          value: t.team_id.toString()
        }))
      }
    ];
  }, [settings]);

  return { advancedSearchFields };
};

export const useGetSubmissionHistoryList = () => {
  const pingId = usePingId();
  const settings = useAppSelector((state) => state.settings.settings);
  const teams = settings?.teams?.map((t) => t.team_id) || [];
  return useGetSubmissionHistoryQuery(
    { id: pingId || "", realTimeSubscriptions: { teams: teams || [] } },
    { skip: !pingId }
  );
};
export const useGetSubmissionList = () => {
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams
  );
  const userId =
    useAppSelector((state) => state.settings?.envData?.user?.id) || 0;

  const settings = useAppSelector((state) => state.settings.settings);

  // when we load the page, we pass all of the teams your a member to do the backend for
  // real-time subscriptions. this allows the backend to send you updates for all submissions
  // via push.
  const teams = settings?.teams?.map((t) => t.team_id) || [];

  const history = useHistory();
  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();
  const { searchValues } = useSearchQueryBuilder(
    fields,
    history,
    SEARCH_PARAM_NAME
  );

  let advancedSearchFields = useMemo(
    () => omit(searchValues, [TEXT_SEGMENT_KEY]),
    [searchValues]
  );

  const slug = useSlug();
  const teamId = useTeamId();
  const pingId = usePingId();

  const key = teamId ? `${teamId}-${slug}` : slug || "";
  const additionFilters = isFunction(navToQueryParams?.[key])
    ? navToQueryParams?.[key](userId)
    : navToQueryParams?.[key] ?? null;

  if (additionFilters) {
    advancedSearchFields = {
      ...advancedSearchFields,
      ...additionFilters
    };
  }
  if (!teamId && !slug && pingId) {
    advancedSearchFields = {
      ...advancedSearchFields,
      id: pingId
    };
  }

  return useGetSubmissionsQuery(
    {
      id: pingId,
      fields: PING_VISION_DEFAULT_FIELDS,
      search: searchValues?.[TEXT_SEGMENT_KEY] ?? null,
      advancedSearchFields,
      realTimeSubscriptions: { teams: teams || [] }
    },
    { skip: !teams.length }
  );
};
