import { FC, useState, useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { 
  PingLucideIcon, 
  PingPdfPreview,
  PingEmailPreview,
  PingCircleIconButton,
} from "@repo/ping-react-js";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { SovDataType } from "ts-types/DataTypes";
import { setPreviewPdf, setClearPreview, setPreviewEmail } from "reducers/inbox";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { getApiBaseUrl } from "src/utils";
import { useGetEmailCorrespondenceQuery } from "services/pvSlice";

import "./PVAllDocumentsPreviewPanel.scss";

// add SOV here later
type DocumentType = "ACORD" | "LOSS_RUN" | "EML";

// add SOV here later
const DOCUMENT_TYPES: DocumentType[] = ["EML", "ACORD", "LOSS_RUN"];

type PVAllDocumentsPreviewPanelProps = {
  selectedItem: SovDataType;
};

export const PVAllDocumentsPreviewPanel: FC<PVAllDocumentsPreviewPanelProps> = ({
  selectedItem,
}) => {
  const dispatch = useAppDispatch();
  const [activeType, setActiveType] = useState<DocumentType | null>(null);
  const [showOtherFiles, setShowOtherFiles] = useState<boolean>(true);

  const documents = useMemo(() => selectedItem?.documents || [], [selectedItem]);

  const documentsByType = useMemo(() => {
    return DOCUMENT_TYPES.reduce((acc, type) => {
      const filteredDocuments = documents.filter(d => d.document_type === type);
      if (filteredDocuments.length > 0) {
        acc[type] = filteredDocuments;
      }
      return acc;
    }, {} as Record<DocumentType, typeof documents>);
  }, [documents]);

  const activeDocuments = useMemo(
    () => (activeType ? documentsByType[activeType] || [] : []),
    [activeType, documentsByType]
  );

  const accessToken = useAppSelector((state) => state.auth.accessToken);

  const documentPreviewType = useAppSelector(
    (state) => state.inbox.documentPreview?.type
  );

  const documentPreviewUrl = useAppSelector((state) =>
    state.inbox.documentPreview?.type === "PDF"
      ? state.inbox.documentPreview?.url
      : null
  );

  const downloadUrl = documentPreviewUrl
    ? new URL(documentPreviewUrl, getApiBaseUrl()).toString()
    : null;

  const { data: email, isFetching } = useGetEmailCorrespondenceQuery(
    { sovid: selectedItem.id },
    { skip: documentPreviewType !== "EMAIL" }
  );

  const documentOptions = useMemo(
    () => ({
      httpHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
    [accessToken]
  );

  const handleDocumentClick = useCallback((doc: typeof documents[0]) => {
    if (doc.document_type === "ACORD" || doc.document_type === "LOSS_RUN") {
      dispatch(setPreviewPdf(doc.url));
    }

    else if (doc.document_type === "EML") {
      dispatch(setPreviewEmail(selectedItem.id));
    }

    // add SOV display logic here later
  }, [dispatch, selectedItem]);

  const onClose = useCallback(() => {
   dispatch(setClearPreview());
  }, [dispatch]);

  const handleSetActiveType = useCallback((type: DocumentType) => {
    setActiveType(type);

    const currentActiveDocuments = documentsByType[type];
    const firstDoc = currentActiveDocuments[0];

    if (firstDoc.document_type === "ACORD" || firstDoc.document_type === "LOSS_RUN") {
      dispatch(setPreviewPdf(firstDoc.url));
    }

    else if (type === "EML") {
      dispatch(setPreviewEmail(selectedItem.id));
    }
    // add SOV display logic here later
  }, [documentsByType, setActiveType, dispatch, selectedItem]);

  const toggleShowOtherFiles = () => {
    setShowOtherFiles((prev) => !prev);
  };

  useEffect(() => {
      if (documentPreviewType === "EMAIL" && activeType !== 'EML') {
          setActiveType('EML');
      } else if (documentPreviewUrl) {
          setShowOtherFiles(false)
          const doc = documents.find((d) => d.url === documentPreviewUrl);
          const docActiveType = doc?.document_type as DocumentType;
          if (docActiveType !== activeType) {
              setActiveType(doc?.document_type as DocumentType);
          }
      }
  }, [activeType, documentPreviewType, documents, setActiveType, documentPreviewUrl]);

  return (
    <div className="PVAllDocumentsPreviewPanel">
      <div className="PVAllDocumentsPreviewPanel__header">
        <div className="PVAllDocumentsPreviewPanel__pills">
          {DOCUMENT_TYPES.map((type) => {
            const count = documentsByType[type]?.length || 0;
            if (count === 0) return null;

            return (
              <button
                key={type}
                className={classNames("PVAllDocumentsPreviewPanel__pill", {
                  "PVAllDocumentsPreviewPanel__pill--active": activeType === type,
                })}
                onClick={() => handleSetActiveType(type)}
              >
                {type === "EML" ? "EMAIL" : type}
                <span className="PVAllDocumentsPreviewPanel__pill-count">
                  {count}
                </span>
              </button>
            );
          })}
        </div>
      </div>

      <div className="PVAllDocumentsPreviewPanel__content">
      {activeType !== "EML" && (<div className="PVAllDocumentsPreviewPanel__files-section">
          {activeDocuments.length > 1 && documentPreviewType && (
        <button
          onClick={toggleShowOtherFiles}
          className={`PVAllDocumentsPreviewPanel__toggle-files-button ${showOtherFiles ? 'PVAllDocumentsPreviewPanel__toggle-files-button--increased-margin-class' : ''}`}
        >
          {showOtherFiles
            ? `Hide Other ${activeDocuments.length - 1} ${activeDocuments.length === 2 ? 'File' : 'Files'}`
            : `Show Other ${activeDocuments.length - 1} ${activeDocuments.length === 2 ? 'File' : 'Files'}`}
        </button>)}
      
          <ul className="PVAllDocumentsPreviewPanel__documents">
            {activeDocuments.map((doc) => {
              if (
                  !documentPreviewType ||
                doc.url === documentPreviewUrl ||
                activeDocuments.length === 1 ||
                showOtherFiles 
              ) {
                return (
                <li key={doc.url}>
                  <button
                    key={doc.url}
                    className={classNames("PVAllDocumentsPreviewPanel__document", {
                      "PVAllDocumentsPreviewPanel__document--active":
                        documentPreviewUrl === doc.url,
                    })}
                    onClick={() => handleDocumentClick(doc)}
                  >
                      {/* aria-hidden should be true in the future, can't pass it to component right now */}
                      <PingLucideIcon
                        iconName={doc.document_type === "EML" ? "Mail" : "File"}
                        size={16}
                      />
                    <div className="PVAllDocumentsPreviewPanel__document-title">
                      {doc.filename}
                    </div>
                  </button>
                  </li>
                );
              }
              return null;
            })}
          </ul>
          </div>)}
        <div className="PVAllDocumentsPreviewPanel__preview">
          {documentPreviewType === "PDF" && downloadUrl && (
            <PingPdfPreview
              file={downloadUrl}
              documentOptions={documentOptions}
              onClose={onClose}
            />
          )}
          {documentPreviewType === "EMAIL" && !isFetching && email && (
            <PingEmailPreview content={email} onClose={onClose} />
          )}
          {/* Put sov display logic here later */}
          {documentPreviewType === "EMPTY" && (
            <div className="PVAllDocumentsPreviewPanel__Empty">
              <PingCircleIconButton iconName="close" onClick={onClose} />
              <PVEmptyPanelMessage
                className="PVAllDocumentsPreviewPanel__Empty__Message"
                message="Select an attachment to preview from the attachments list"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
