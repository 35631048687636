import { FC, useMemo } from "react";
import cx from "classnames";

import {
  LucideIconName,
  PingLucideIcon,
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "@repo/ping-react-js";

import {
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
} from "utils/submission";
import { getInceptionDateStatus } from "features/submission-dashboard/inception-date-status";
import { SovDataType } from "ts-types/DataTypes";

import "./PVSubmissionPill.scss";

type PVSubmissionPillProps = {
  sov: SovDataType;
};

export const PVSubmissionPill: FC<PVSubmissionPillProps> = ({ sov }) => {
  const triageOverallResultData = getHumanFriendlyTriageScore(
    sov?.triage_rules_overall_result
  );
  const readinessResult = getHumanFriendlyDataReadinessScore(
    sov.data_readiness_score
  );

  const inceptionDateStatus = useMemo(
    () =>
      getInceptionDateStatus(
        sov?.inception_date,
        "PVSubmissionPill__DaysToIncept"
      ),
    [sov]
  );

  return (
    <div className="PVSubmissionPill">
      <div
        className={cx(
          "PVSubmissionPill__PillItem",
          "PVSubmissionPill__DaysToIncept",
          inceptionDateStatus.additionalClassName
        )}
      >
        <PingTooltip placement="left-end">
          <PingTooltipTrigger asChild>
            {inceptionDateStatus.label}
          </PingTooltipTrigger>
          <PingTooltipContent>{inceptionDateStatus.tooltip}</PingTooltipContent>
        </PingTooltip>
      </div>

      {readinessResult && (
        <div
          className={cx(
            "PVSubmissionPill__PillItem",
            "PVSubmissionPill__Readiness",
            "PVSubmissionPill__Readiness--" + readinessResult.level
          )}
        >
          <PingTooltip placement="left-end">
            <PingTooltipTrigger asChild>
              <div>
                <PingLucideIcon
                  iconName={readinessResult.icon as LucideIconName}
                />
              </div>
            </PingTooltipTrigger>
            <PingTooltipContent>
              Data Readiness: {readinessResult.label}
            </PingTooltipContent>
          </PingTooltip>
        </div>
      )}
      {triageOverallResultData && (
        <div
          className={cx(
            "PVSubmissionPill__PillItem",
            "PVSubmissionPill__Triage_Result",
            "PVSubmissionPill__Triage_Result--" + triageOverallResultData?.level
          )}
        >
          <PingTooltip placement="left-end">
            <PingTooltipTrigger asChild>
              <div>
                <PingLucideIcon
                  iconName={triageOverallResultData.icon as LucideIconName}
                />
              </div>
            </PingTooltipTrigger>
            <PingTooltipContent>
              Recommendation: {triageOverallResultData.label}
            </PingTooltipContent>
          </PingTooltip>
        </div>
      )}
    </div>
  );
};
