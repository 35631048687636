import { differenceInDays, isValid, parseISO } from "date-fns";
import { formatDistance, formatDistanceToNow } from "date-fns";

/**
 * Formats an ISO date string into a short date-time string.
 *
 * For example:
 *
 * ```typescript
 * formatShortDateTime("2023-10-05T14:48:00.000Z"); // Returns "10/05/2023 at 02:48 PM"
 * formatShortDateTime("2023-10-05T14:48:00.000Z", " "); // Returns "10/05/2023 at 02:48 PM"
 * formatShortDateTime(null); // Returns ""
 * ```
 */
export const formatShortDateTime = (
  isoString: string | null,
  datetimeSeparator: string = " at"
): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    date
  );

  return formattedDateTime.replace(",", datetimeSeparator);
};

/**
 * Formats an ISO date string into a short date string.
 *
 * For example:
 *
 * ```typescript
 * formatShortDate("2023-10-05T14:48:00.000Z"); // Returns "10/5/23"
 * formatShortDate(null); // Returns ""
 * ```
 */
export const formatShortDate = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "short",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

/**
 * Formats an ISO date string into a short, friendly, human readable date
 * format.
 *
 * For example:
 *
 * ```typescript
 * formatShortFriendlyDate("2023-10-05T14:48:00.000Z"); // "Oct 05, 2023"
 * formatShortFriendlyDate(null); // ""
 * ```
 */
export const formatShortFriendlyDate = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatLongDate = (isoString: string | null): string => {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    date
  );

  return formattedDateTime;
};

export function formatDateTimeStringWithAgo(value: string) {
  const date = new Date(value);
  return formatDistanceToNow(date, { addSuffix: true }).replace("about ", "");
}

export function formatDurationWithAgo(startTime: string, endTime: string) {
  if (!startTime || !endTime) {
    return null;
  }
  const start = new Date(startTime);
  const end = new Date(endTime);
  const duration = formatDistance(end, start, {
    includeSeconds: true,
  });
  return duration;
}

/**
 * Calculates the number of days between two dates. Dates can be passed in as
 * `Date` objects or ISO-8601 date strings.
 *
 * For example:
 *
 * ```typescript
 * daysBetween(new Date('2023-01-01'), new Date('2023-01-10')); // Returns -9
 * daysBetween('2023-01-01', '2023-01-10'); // Returns -9
 * daysBetween(null, '2023-01-10'); // Returns null
 * daysBetween('invalid-date', '2023-01-10'); // Returns null
 * ```
 */
export const daysBetween = (
  dateLeft: Date | string | null,
  dateRight: Date | string | null
): number | null => {
  // Convert strings to Dates if necessary and validate the inputs
  if (typeof dateLeft === "string") {
    dateLeft = parseISO(dateLeft);
  }
  if (typeof dateRight === "string") {
    dateRight = parseISO(dateRight);
  }
  if (!isValid(dateLeft) || !isValid(dateRight)) {
    return null;
  }

  // Calculate and return the difference in days
  return differenceInDays(dateLeft, dateRight);
};
